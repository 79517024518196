import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import {
  CONFIG_REQUEST,
  CONFIG_REQUEST_FAIL,
  CONFIG_REQUEST_SUCCESS,
  SET_PAGE_CONFIG,
  SEND_TAGS,
  setGlobalLoading,
  setTaggingSheet,
} from "actions/configActions";
import { setError } from "actions";
import { CHECK_SAML_AUTH, AUTHORIZED_SAML } from "actions/authenticationActions";
import { analyticsSelector, tealiumConfigSelector } from "selectors/analyticsSelector";
import configService from "services/ConfigService";
import { LANGUAGE_REQUEST, LANGUAGE_LIST_REQUEST_SUCCESS, LANGUAGE_LIST_REQUEST_FAIL } from "actions/languageActions";
import { application } from "../settings.json";

import TranslationService from "services/TranslationService";
import { logout } from "services/Liveness";
import { TAGS_HANDLERS } from "constants/index";

function* configWorker() {
  let newConfig;
  const isAuthenticatedApp = yield select((state) => state?.authentication?.isAuthApp);
  const auth = JSON.parse(localStorage.getItem("auth"));
  if (auth || !isAuthenticatedApp) {
    yield put(setGlobalLoading(true));
  }
  const configItemJSON = localStorage.getItem(`config-${application || "appId"}`);
  const configItem = configItemJSON && JSON.parse(configItemJSON);
  if (configItem && Date.now() < configItem?.fetchDate + configItem?.global?.TTL * 1000) {
    newConfig = configItem;
    yield put(setGlobalLoading(false));
    yield put({
      type: CONFIG_REQUEST_SUCCESS,
      payload: { ...configItem, appId: application },
    });
    if (Object.keys(configItem.pages).length) {
      yield put({
        type: SET_PAGE_CONFIG,
        payload: configItem.pages,
      });
    }
  } else {
    const defaultAppLanguage = configItem?.global?.defaultAppLanguage;
    if (defaultAppLanguage) {
      console.log("Language called from existing config from local storage");
      yield put({
        type: LANGUAGE_REQUEST,
        payload: defaultAppLanguage,
      });
    }

    try {
      const data = yield call(configService.fetchConfig);
      newConfig = data;
      if (!localStorage.getItem("lang")) localStorage.setItem("lang", newConfig?.global?.defaultAppLanguage);
      yield put({
        type: CONFIG_REQUEST_SUCCESS,
        payload: { ...data, appId: application },
      });
      if (Object.keys(data.pages).length) {
        yield put({
          type: SET_PAGE_CONFIG,
          payload: data.pages,
        });
      }
    } catch (error) {
      yield put({ type: CONFIG_REQUEST_FAIL, error });
      yield put(setError(error.response?.status || 500, { type: CONFIG_REQUEST }));
      logout();
    } finally {
      yield put(setGlobalLoading(false));
    }
  }
  try {
    const { data } = yield call(TranslationService.fetchLanguages);
    yield put({
      type: LANGUAGE_LIST_REQUEST_SUCCESS,
      payload: {
        languages: data,
      },
    });

    const defaultAppLanguage = localStorage.getItem("lang") || newConfig.global.defaultAppLanguage;
    const languagesState = yield select((state) => state.language.languages);
    const languages = Object.keys(languagesState || {});
    const languageIndex = languages.findIndex((lang) => defaultAppLanguage === lang);
    const language = languageIndex === -1 ? "en" : defaultAppLanguage;
    console.log("Language: ", language, newConfig, defaultAppLanguage, languagesState);
    if (language) console.log("Language set to ", language, "from calculation");
    yield put({
      type: LANGUAGE_REQUEST,
      payload: language,
    });
  } catch (error) {
    yield put({ type: LANGUAGE_LIST_REQUEST_FAIL, error });
  }
}

function* configWatcher() {
  yield takeLatest(CONFIG_REQUEST, configWorker);
}

function* samlAuthWorker({ payload }) {
  try {
    const status = yield call(configService.checkSamlAuthorization, payload);
    yield put({
      type: AUTHORIZED_SAML,
      payload: {
        status,
      },
    });
  } catch (error) {
    console.log(error);
  }
}

function* samlAuthWatcher() {
  yield takeLatest(CHECK_SAML_AUTH, samlAuthWorker);
}

function* handleTealiumCalling({ taggingId, taggingType, taggingValue }) {
  const { taggingSheet } = yield select(analyticsSelector);
  const taggingCriteria = taggingId || taggingValue;

  let tag = taggingSheet.find((obj) => obj["tagging_id"] === taggingCriteria);
  tag = { ...tag, ...taggingValue };
  if (!window.utag) {
    const script = document.getElementById("utagScript");
    if (script) {
      script.addEventListener("load", () => {
        console.log("UTAG:", window.utag[taggingType](tag));
      });
    }
  } else {
    console.log("UTAG:", window.utag[taggingType](tag));
  }
}

function* handleDDQCalling({ taggingType, taggingValue }) {
  const _ddq = window._ddq || (window._ddq = []);
  // log warning if _ddq is not found
  if (!window._ddq) console.log("DDQ object is not found.");
  // map tagging type to ddq type
  const type = taggingType === "view" ? "pageview" : taggingType;
  try {
    _ddq.push([type, taggingValue]);
  } catch (error) {
    console.log("Error in DDQ calling: ", error);
  }
}

function* handleGoogleAnalyticsCalling({ taggingValue }) {
  if (window.dataLayer) {
    console.log({ taggingValue, dataLayer: window.dataLayer });
    console.log("Google Analytics:", window.dataLayer.push(taggingValue));
  } else {
    console.log("Google analytics dataLayer object is not found.");
  }
}

function* handleSendTags({ payload }) {
  // read config and check tags handler type
  const tagsHandler = (yield call(configService.getWidgetConfig, "tagsHandler")).toLowerCase();
  console.log("TagsHandler being used: ", tagsHandler);

  switch (tagsHandler) {
    case TAGS_HANDLERS.DDQ:
      yield handleDDQCalling(payload);
      break;
    case TAGS_HANDLERS.GOOGLE:
      yield handleGoogleAnalyticsCalling(payload);
      break;
    default:
      yield handleTealiumCalling(payload);
      break;
  }
}

function* sendTealiumTagsWatcher() {
  try {
    yield takeEvery(SEND_TAGS, handleSendTags);
  } catch (error) {
    console.error("Send TagsWatcher error: ", error);
  }
}
export function* getTaggingSheetWatcher() {
  try {
    yield takeLatest(CONFIG_REQUEST_SUCCESS, getTaggingSheet);
  } catch (error) {
    console.error(error);
  }
}

export function* getTaggingSheet() {
  const tealiumConfig = yield select(tealiumConfigSelector);
  let taggingSheet = [];
  if (tealiumConfig?.length > 0 && tealiumConfig[0]?.tealiumIsEnabled && tealiumConfig[0]?.taggingSheetJson) {
    taggingSheet = tealiumConfig[0]?.taggingSheetJson;
  } else if (tealiumConfig?.length > 0 && tealiumConfig[0]?.tealiumIsEnabled && tealiumConfig[0]?.taggingSheetUrl) {
    taggingSheet = yield call(configService.fetchTaggingSheet, tealiumConfig[0]?.taggingSheetUrl);
  }
  yield put(setTaggingSheet(taggingSheet));
}

const configSaga = [configWatcher(), samlAuthWatcher(), sendTealiumTagsWatcher(), getTaggingSheetWatcher()];

export default configSaga;
